
:root {
    --basecolor1:#2094fc;
    /* rgba(32, 148, 252, 1) */
    --basecolor2:#333333;
    --basecolor3:#fff404;
    --basecolor4:#ffffff;
    --basecolor5:#eeeeee;
    --basecolor5_2:#dddddd;
    --basecolor5_3:#fefefe;
    --basecolor5_4:#efefef;
    --basecolor5_5:#9a9a9a;
    --basecolor6:#555555;
    --basecolor7:#777777;
    --basecolorred:#dc2f02;
    --basecoloryellow:#ffea00;
    --basecolorgreen:#55a630;
    --basecolorblue:#039ED3;
}
::selection {
    background: var(--basecolor1) !important;
    color: white !important;
}
img, br {user-select: none !important;}
* {
    outline: none !important;
    transition: 250ms ease-in-out;
    scroll-behavior: smooth;
}
body {
    letter-spacing: -0.25px;
    background: #fff;
    font-family: SFProTextRegular !important;
}
/* Custom CSS */ 
.modal-dialog-5 {max-width: 5% !important;} .modal-dialog-10 {max-width: 10% !important;} .modal-dialog-15 {max-width: 15% !important;} .modal-dialog-20 {max-width: 20% !important;} .modal-dialog-25 {max-width: 25% !important;} .modal-dialog-30 {max-width: 30% !important;} .modal-dialog-35 {max-width: 35% !important;} .modal-dialog-40 {max-width: 40% !important;} .modal-dialog-45 {max-width: 45% !important;} .modal-dialog-50 {max-width: 50% !important;} .modal-dialog-55 {max-width: 55% !important;} .modal-dialog-60 {max-width: 60% !important;} .modal-dialog-65 {max-width: 65% !important;} .modal-dialog-70 {max-width: 70% !important;} .modal-dialog-75 {max-width: 75% !important;} .modal-dialog-80 {max-width: 80% !important;} .modal-dialog-85 {max-width: 85% !important;} .modal-dialog-90 {max-width: 90% !important;} .modal-dialog-95 {max-width: 95% !important;} .modal-dialog-100 {max-width: 100% !important;}
@media screen and (max-width: 768px) {
    .modal-dialog-5, .modal-dialog-10, .modal-dialog-15, .modal-dialog-20, .modal-dialog-25, .modal-dialog-30, .modal-dialog-35, .modal-dialog-40, .modal-dialog-45, .modal-dialog-50, .modal-dialog-55, .modal-dialog-60, .modal-dialog-65, .modal-dialog-70, .modal-dialog-75, .modal-dialog-80, .modal-dialog-85, .modal-dialog-90, .modal-dialog-95, .modal-dialog-100
    {max-width: 100% !important;}
}
.font-10 {font-size:10px !important;}.font-11 {font-size:11px !important;}.font-12 {font-size:12px !important;}.font-13 {font-size:13px !important;}.font-14 {font-size:14px !important;}.font-15 {font-size:15px !important;}.font-16 {font-size:16px !important;}.font-17 {font-size:17px !important;}.font-18 {font-size:18px !important;}.font-19 {font-size:19px !important;}.font-20 {font-size:20px !important;}.font-21 {font-size:21px !important;}.font-22 {font-size:22px !important;}.font-23 {font-size:23px !important;}.font-24 {font-size:24px !important;}.font-25 {font-size:25px !important;}.font-26 {font-size:26px !important;}.font-27 {font-size:27px !important;}.font-28 {font-size:28px !important;}.font-29 {font-size:29px !important;}.font-30 {font-size:30px !important;}
.modal-content {border-radius:12px !important;}
.modal-footer button {border-radius: 8px !important;transition: 250ms ease-in-out;}
.modal-footer .btn-primary {background: var(--basecolor1);border-color: var(--basecolor1);}
.modal-footer .btn-primary:hover {filter: brightness(1.2);}
.button-mini {border: none;padding: 0.1px;font-size: 0.1px;outline: none;cursor: none;}
/* .bgcolor1 {background: var(--basecolor1) !important;} 
.bgcolor2 {background: var(--basecolor2) !important;}
.bgcolor3 {background: var(--basecolor3) !important;}
.bgcolor4 {background: var(--basecolor4) !important;}
.textcolor1 {color: var(--basecolor1) !important;}
.textcolor2 {color: var(--basecolor2) !important;}
.textcolor3 {color: var(--basecolor3) !important;}
.textcolor4 {color: var(--basecolor4) !important;} */
.borderradius12 {border-radius: 12px !important;}
.borderradius8 {border-radius: 8px !important;}
.alert {border-radius: 12px !important;}
.cursorpointer {cursor: pointer !important;}
.loading_riplabs {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 3px;
    z-index: 99999999;
    background-color: var(--basecolor1);
    animation: loading_riplabs 4.5s ease-in-out;
}
@keyframes loading_riplabs {
    0% {width:0%;}
    25% {width:25%;}
    50% {width:50%;}
    75% {width:75%;}
    100% {width:100%;}
}

.navbar-desktop {
    padding: 13px 0 13px 0;
    border-bottom: 1px solid var(--basecolor5);
    width: 100%;
}
.header2 .container {
    width: 98% !important;
    max-width: 98% !important;
}
.navbar-desktop .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar-desktop .left .logo {
    width: 110px;
    /* border: 1px solid red; */
}
.navbar-desktop .right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.navbar-desktop .right a {
    font-size:16px;
    margin: 0 20px;
    padding: 10px 0;
    color:var(--basecolor6);
}
.navbar-desktop .right a.active {
    color:var(--basecolor1) !important;
}
.navbar-desktop .right a:hover {
    color:var(--basecolor1);
}
.nav-masuk {
    border: 1px solid var(--basecolor5_2);
    border-radius: 8px;
    padding: 8px 16px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    color:var(--basecolor6) !important;
}
.nav-masuk:hover {
    background: var(--basecolor5);
}
.nav-daftar {
    border: 1px solid var(--basecolor5_2);
    border-radius: 8px;
    padding: 8px 16px !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
    color:var(--basecolor6) !important;
}
.nav-daftar:hover {
    background: var(--basecolor5);
}

.navbar-mobile {
    display: none;
    padding: 15px 0 15px 0;
    border-bottom: 1px solid var(--basecolor5);
    width: 100%;
}
.navbar-mobile .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar-mobile .linkmenukategoriapps {
    border: none !important;
    color: var(--basecolor2);
    width:48px;
    height:46px;
    font-size: 1.13rem !important;
    /* margin-right: 15px; */
    border-radius: 50% !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none !important;
    background:var(--basecolor5_3);
    padding: 0px !important;
}
.navbar-mobile .linkmenukategoriapps:hover {
    color: var(--basecolor2);
    background:rgba(32, 148, 252, 0.2);
}

.navbar-mobile .linkmenu {
    padding: 3px 6px;
    font-size: 15px;
    font-family: SFProTextMedium;
    border: 2px solid var(--basecolor2);
    border-radius: 0px;
}
.navbar-mobile .todashboardpage {

}
@media screen and (max-width: 768px) {
    .navbar-mobile .linkmenukategoriapps {
        background:rgba(32, 148, 252, 0.04);
        width:auto;
        height:auto;
        padding: 6px 10px !important;
        border-radius: 8px !important;
        font-size: 16px !important;
    }
}
.offcanvas {
    width: 70%;
}
.offcanvas-body {
    padding: 0px !important;
    padding-top: 20px !important;
}
.offcanvas-body span,
.offcanvas-body a {
    margin-bottom: 5px;
    color: var(--basecolor2);
    padding: 15px 20px;
    width: 100% !important;
    cursor: pointer;
}
.offcanvas-body span.active, 
.offcanvas-body span:hover,
.offcanvas-body a.active, 
.offcanvas-body a:hover {
    /* border-radius: 50px 0 0 50px; */
    color: var(--basecolor1);
    background: var(--basecolor5);
}

@media screen and (max-width: 768px) {
    .navbar-desktop {display: none !important;}
    .navbar-mobile {display: block !important;}
}
/* End Navbar */

.boxtambahkategorisimple .body {display: none;}

.rowcard1 .card-title,
.rowcard1 .card-title * {
    font: 26px/37px SFProTextSemibold;
    color: var(--basecolor2) !important;
    margin-bottom:15px;
}
.rowcardapps .card-title:hover,
.rowcardapps .card-title *:hover,
.riplabsartikel .card-title:hover,
.riplabsartikel .card-title *:hover {
    color: var(--basecolor1) !important;
}
.rowcard1 .card-title:hover,
.rowcard1 .card-title *:hover {
    /* color: var(--basecolor1) !important; */
}
.rowcard1 .card-text {
    font: 16px/26px SFProTextRegular;
    
}
.rowcard1 .estimasiharga {
    font: 22px/32px SFProTextSemibold;
    color: var(--basecolor2);
}
.rowcard1 .estimasiharga2 {
    font: 30px/35px SFProTextSemibold;
    color: var(--basecolor2);
}
.rowcard1 .image {
    height:250px;
    overflow: hidden !important;
    border-radius: 12px;
    margin-bottom:20px;
}
.rowcard1 .image img {
    transition: 200ms ease-in-out !important;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    object-position: top;
}
.rowcard1 .image:hover > img {
    transform: scale(1.03) !important; 
}
.riplabsportofolio .rowcard1 .image img {
    transition: 2.5s ease-in-out !important;
}
.riplabsportofolio .rowcard1 .image:hover > img {
    object-position: bottom !important;
}
.rowcardnoborder .card {
    border:none;
}
.rowcardnoborder .card-body {
    padding: 0px !important;
}
.rowcardnoborder .card-footer {
    padding: 0px !important;
    border:none;
    background:none;
}
@media screen and (max-width: 768px) {
    .rowcard1 .image {  
        height:225px;   
    }
}


.subheader h1 {
    font-family: SFProTextSemibold;
    letter-spacing: -1px;
}
.subheader p {
    color: var(--basecolor6);
    font-size: 16px;
}
.subheader {
    color: black;
    width: 100%;
    padding: 90px 0;
    background: linear-gradient(to right, rgba(32, 148, 252, 0.02) 5%, var(--basecolor4) 80%);
}
@media screen and (max-width: 768px) {
    .subheader {
        padding: 55px 0 45px 0;
    }
}


.pagehome .hero {
    background: linear-gradient(to right, rgba(32, 148, 252, 0.02)10%, var(--basecolor4) 80%);
    height: 70vh;
    display: flex;
    align-items: center;
}
.pagehome2 .hero {
    /* border: 1px solid red; */
    height: 90vh;
    display: flex;
    align-items: center;
}
.pagehome2 .hero img.imggif {
    display: block;
    margin: 0 auto 20px auto;
    width: 50%;
    border-radius: 12px;
    border: 3px solid var(--basecolor1);
}
.pagehome2 .hero .container {
    padding: 0% !important;
}
@media screen and (max-width: 1000px) {
    .pagehome2 .hero img.imggif {
        width:90%;
    }
}
@media screen and (max-width: 768px) {
    .pagehome2 .hero img.imggif {
        width:100%;
    }
}
.pagehome .hero .container {
    padding-right: 20%;
}
.pagehome .hero h1 {
    font: 60px/72px SFProTextBold;
    color: #111;
    margin-bottom: 15px;
    letter-spacing: -2px;
}
.pagehome .hero p {
    color: var(--basecolor6);
    font: 18px/31px SFProTextRegular;
    margin-bottom: 25px;
}
.pagehome .hero .btn_hero_1 {
    font: 15px/15px SFProTextRegular;
    border-radius: 50px;
    color: white;
    outline: none;
    text-decoration: none !important;
    border: none;
    padding: 12px 20px;
    background: var(--basecolor1);
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
}
.pagehome .hero .btn_hero_1:hover {
    filter: brightness(1.2);
}
.pagehome .hero .btn_hero_2 {
    font: 15px/15px SFProTextRegular;
    border-radius: 50px;
    color: white;
    outline: none;
    text-decoration: none !important;
    border: none;
    padding: 12px 20px;
    background: var(--basecolor2);
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
}
.pagehome .hero .btn_hero_2:hover {
    filter: brightness(1.5);
}
@media screen and (max-width: 768px) {
    .pagehome .hero h1 {
        font: 35px/48px SFProTextBold;
    }
    .pagehome .hero p {
        font: 16.5px/26.5px SFProTextRegular;
    }
}

.pagesharefiles .hero h1 {
    font: 45px/55px SFProTextBold;
}
.pagesharefiles input {
    background-color: white !important;
}
.pagesharefiles .hero .container {
    padding-right: 0%;
}
.pagesharefiles .judullink {
    color: var(--basecolor2);
}
.pagesharefiles .judullink:hover {
    color: var(--basecolor1);
}
.pagesharefiles .unggahfile {
    font-size: 17px;
    padding: 15px 25px;
    border-radius: 12px !important;
    transition: 200ms ease-in-out;
}
.pagesharefiles .unggahfile:hover {
    filter: brightness(1.2);
}
.pagesharefiles .boxunggahfile {
    display: flex;
    width: 80%;
    border-radius: 10000px;
    border: 3px solid rgba(32, 148, 252, 0.2);
    background: rgba(32, 148, 252, 0.05);
    padding: 60px;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width: 768px) {
    .pagesharefiles .boxunggahfile {
        padding: 50px;
        border-radius: 25px;
        width: 100%;
        flex-direction: column;
        justify-content: center;       
    }
}



.form-control, .form-select {
    border-radius: 8px;
}
.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 148, 252, 0.4) !important;
}
.btn {border-radius: 8px !important;}
.btn-primary, .btn-info {background: var(--basecolor1) !important;border-color:var(--basecolor1) !important;}
.btn-success {background: var(--basecolorgreen) !important;border-color:var(--basecolorgreen) !important;}
.btn-danger {background: var(--basecolorred) !important;border-color:var(--basecolorred) !important;}
.btn-warning {background: var(--basecoloryellow) !important;border-color:var(--basecoloryellow) !important;}
.modal-header, .modal-footer {border:none !important;}

.button-blue-1 {
    border-radius: 8px;
    border: none;
    user-select: none;
    background: var(--basecolor1);
    padding: 12px 20px;
    color: white !important;
    font: 16px/16px SFProTextMedium;
    text-decoration: none !important;
    outline: none;
}
.button-blue-1:hover {filter: brightness(1.2) !important;}
.button-blue-1:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 148, 252, 0.4) !important;
}

.riplabsapps {
    display: block;
    margin-top: 40px;
}
.riplabsapps2 {
    display: block;
    margin-top: 60px;
}
@media screen and (max-width: 768px) {
    .riplabsapps {
        margin-top: 30px;
    }
}
hr {background: var(--basecolor6) !important;}
.hrcustom1 {background: var(--basecolor5) !important;opacity: 1 !important;}
.riplabs-link {color: var(--basecolor1);text-decoration: none !important;}
.riplabs-link:hover {filter: brightness(1.2) !important;}
.riplabs-h3 {
    font: 35px/35px SFProTextSemibold;
    color: var(--basecolor2);
}
.riplabs-h5 {
    font: 25px/25px SFProTextSemibold;
    color: var(--basecolor2);
}




.pagelogin {
    /* height: 100vh; */
    background: linear-gradient(to right, var(--basecolor4), rgba(32, 148, 252, 0.02), var(--basecolor4));
}
.pagelogin .box {
    width: 29%;
    display: block;
    margin: auto;
    margin-top: 40px;
    padding: 30px;
    border:1px solid var(--basecolor5);
    border-radius: 12px;
    background: white;
}
.registerpage {
    /* height: 100vh; */
    background: linear-gradient(to right, var(--basecolor4), rgba(32, 148, 252, 0.02), var(--basecolor4));
}
.registerpage .box {
    width: 29%;
    display: block;
    margin: auto;
    margin-top: 40px;
    padding: 30px;
    border:1px solid var(--basecolor5);
    border-radius: 12px;
    background: white;
}
@media screen and (max-width: 768px) {
    .pagelogin .box,
    .registerpage .box {
        width: 94% !important;
    }
}






@media screen and (max-width: 768px) {
    .container {
        max-width:95%;
        width:95%;
    }
    .pagehome .hero .container {
        padding-right: 0;
    }
}




.comingsoon {
    cursor: pointer;
    background: var(--basecolor7) !important;
    color: white;
    display: flex;
    align-items: center;
    opacity: 0.9;
    border-radius: 12px;
    /* box-shadow:0 .5rem 1rem rgba(0,0,0,0.15)!important; */
    text-decoration: none !important;
    justify-content: center;
    padding: 30px 0;
    height: 100%;
    font: 20px/25px SFProTextMedium;
    color:white !important;
}
.viewother {
    cursor: pointer;
    background: var(--basecolor1) !important;
    color: white;
    display: flex;
    align-items: center;
    opacity: 0.9;
    border-radius: 12px;
    /* box-shadow:0 .5rem 1rem rgba(0,0,0,0.15)!important; */
    text-decoration: none !important;
    justify-content: center;
    padding: 30px 0;
    height: 100%;
    font: 20px/25px SFProTextMedium;
    color:white !important;
}
.viewother:hover > .fa-arrow-right {
    margin-left: 4px;
}
#loading_window {
    position:fixed;
    z-index:99999999999999999999999999999999;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.6);
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:none;
    justify-content:center;
    align-items:center;
}
#loading_window img {
    width: 170px !important;
}

.font-regular {font-family: SFProTextRegular !important;}
.font-medium {font-family: SFProTextMedium !important;}
.font-semibold {font-family: SFProTextSemibold !important;}
.font-bold {font-family: SFProTextBold !important;}
.textdecorationnone {text-decoration: none !important;}

.showhidemenuapps {
    color: var(--basecolor2);
    width:48px;
    height:46px;
    font-size: 1.15rem;
    margin-right: 15px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none !important;
    /* background:rgba(32, 148, 252, 0.02); */
    background:var(--basecolor5_3);
}
.showhidemenuapps:hover {
    color: var(--basecolor2);
    background:rgba(32, 148, 252, 0.2);
}
.swal2-popup {border-radius:12px !important;} 
.swal2-confirm, .swal2-cancel {border-radius:8px !important;} 
.color1 {color: var(--basecolor1) !important;}
.color2 {color: var(--basecolor2) !important;}
.color3 {color: var(--basecolor3) !important;}
.color4 {color: var(--basecolor4) !important;}
.color5 {color: var(--basecolor5) !important;}
.color5_2 {color: var(--basecolor5_2) !important;}
.color5_3 {color: var(--basecolor5_3) !important;}
.color5_4 {color: var(--basecolor5_4) !important;}
.color5_5 {color: var(--basecolor5_5) !important;}
.color6 {color: var(--basecolor6) !important;}
.color7 {color: var(--basecolor7) !important;}
.colorred {color: var(--basecolorred) !important;}
.coloryellow {color: var(--basecoloryellow) !important;}
.colorgreen {color: var(--basecolorgreen) !important;}
.colorblue {color: var(--basecolorblue) !important;}
.bgcolor1 {background: var(--basecolor1) !important;}
.bgcolor2 {background: var(--basecolor2) !important;}
.bgcolor3 {background: var(--basecolor3) !important;}
.bgcolor4 {background: var(--basecolor4) !important;}
.bgcolor5 {background: var(--basecolor5) !important;}
.bgcolor5_2 {background: var(--basecolor5_2) !important;}
.bgcolor5_3 {background: var(--basecolor5_3) !important;}
.bgcolor6 {background: var(--basecolor6) !important;}
.bgcolor7 {background: var(--basecolor7) !important;}
.bgcolorred {background: var(--basecolorred) !important;}
.bgcoloryellow {background: var(--basecoloryellow) !important;}
.bgcolorgreen {background: var(--basecolorgreen) !important;}
.bgcolorblue {background: var(--basecolorblue) !important;}

.pagedashboard {

}
.pagedashboard h2 {
    font-family: SFProTextSemibold;
    letter-spacing: -1px;
}
.boxpagedashboard .box {
    /* border: 1px solid red; */
    border-radius: 12px;
    /* height: 300px; */
    height: 100%;
    /* padding: 20px; */
    /* margin: 10px; */
    box-shadow: 1px 1px 0.2rem rgba(0,0,0,0.1);
}
.boxpagedashboard .box:hover {
    transform: scale(1.005);
}

.boxpagedashboard .box .body {
    padding: 20px;

}
.boxpagedashboard .box .header {
    padding: 20px;
    /* border: 1px solid red; */
    border-radius: 12px 12px 0 0;
    background: var(--basecolor5_3);
}

.boxidsection .namapanjangku {
    word-break: break-word;
}
.boxidsection .emailku {
    word-break: break-word;
}

.boxpagedashboard .aktivitasku {
    height: 190px;
    padding: 15px;
    margin: 15px;
    overflow: scroll;
    border-radius: 8px;
    background: var(--basecolor5_3);
}
.boxpagedashboard .aktivitaskuX {
    height: 190px;
    padding: 15px;
    border-radius: 8px;
    margin: 15px;
}
.boxpagedashboard .aktivitasku h6 {
    margin-bottom: 2px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.boxpagedashboard .aktivitasku p { 
    margin-bottom: 15px;
    color: var(--basecolor6);
    font-size: 12px;
}

.boxpageaccount {
    margin: 30px 0 40px 0;
    padding: 25px;
    border: 1px solid var(--basecolor5);
    border-radius: 12px;
}

.boxpageaccount .col-lg-10 {
    padding-top: 1rem;
}

.btnloadmoreaktivitasku {display: none;}
@media screen and (max-width: 768px) {
    .boxpagedashboard .aktivitaskuX {
        height: 0px;
    }
    .boxpageaccount .col-lg-10 {
        padding-top: 0.25rem;
    }
}
.boxdonasiriplabs * {
    font:22px/34px SFProTextMedium;
    color: white;
}
.boxdonasiriplabs {
    margin-top: 40px;
    background: var(--basecolor1);
    padding: 60px 4% 50px 4%;
    text-align: center;
    display: block;
}

.btnaddapps {
    padding: 4px 12px;
    border-radius: 8px;
}
.btnaddapps:hover {
    background: var(--basecolor5);
}
.btntitleapps {
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    display: block;
}
.btntitleapps:hover {
    background: var(--basecolor5);
}


.aplikasiku-wrapper {
    width:100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.aplikasiku-wrapper .sidebar {
    padding-top: 20px;
    width: 18%;
    /* background:var(--basecolor5_3); */
    /* border: 1px solid red; */
    /* padding: 20px; */
}
.aplikasiku-wrapper .sidebar .menukategori {
    display: block;
    padding: 15px 20px;
    text-decoration: none !important;
    color: var(--basecolor2);
    font-family: SFProTextMedium;
    border-radius: 0 50px 50px 0;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.aplikasiku-wrapper .sidebar .menukategori.active {
    color: var(--basecolor1);
    background: var(--basecolor5);
}
.aplikasiku-wrapper .sidebar .menukategori:hover {
    color: var(--basecolor1);
    background: var(--basecolor5);
}
.aplikasiku-wrapper .body {
    width: 82%;
    /* border: 1px solid red; */
    background: white;
    padding: 20px;
    padding-bottom: 40px;
}
.aplikasiku-wrapper .listcatatanku .title {
    /* font: 20px/26px SFProTextMedium;
    margin: 0px !important; */
}
.aplikasiku-wrapper .listcatatanku .content-footer {
    padding-top: 10px;
    cursor: auto !important;
}
.aplikasiku-wrapper .listcatatanku .content-footer a {
    width: 40px;
    height: 40px;
    font-size: 0.95rem;
    margin: 0px !important;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50px;
    background-color: var(--basecolor5);
}
.aplikasiku-wrapper .listcatatanku .content-footer a.hapuscatatan:hover > * {
    color: var(--basecolorred);
}
.aplikasiku-wrapper .listcatatanku .content-footer a.arsipkancatatan:hover > * {
    color: var(--basecolorgreen);
}
.aplikasiku-wrapper .boxtugaskulistterselesaikan {display: none;}
.aplikasiku-wrapper .listtugasku {
    padding: 15px !important;
    display: flex;
    justify-content: space-between;
    width:100%;
    margin-bottom: 20px !important;
}
/* .aplikasiku-wrapper .listtugasku .title {
    font: 17px/21px SFProTextMedium;
    margin: 0px !important;
} */
.aplikasiku-wrapper .title {
    font: 17px/21px SFProTextMedium;
    margin: 0px !important;
}
/* .aplikasiku-wrapper .listceritaku .title {
    font: 19px/23px SFProTextMedium;
    margin: 0px !important;
} */
.aplikasiku-wrapper .listtugasku a {
    width: 40px;
    height: 40px;
    font-size: 1rem;
    margin: 0px !important;
    /* display: inline-flex; */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50px;
    color: var(--basecolor7);
    text-decoration: none !important;
    background-color: var(--basecolor5_4);
}
.aplikasiku-wrapper .listtugasku:hover > div div .ceklistugasku {
    display: flex !important;
}
.aplikasiku-wrapper .listtugasku:hover > div div .kembalikantugasku {
    display: flex !important;
}
.aplikasiku-wrapper .listtugasku:hover > div .hapustugasku {
    display: flex !important;
}
.aplikasiku-wrapper .listtugasku a.hapustugasku:hover > * {
    color: var(--basecolorred);
}
.aplikasiku-wrapper .listtugasku a.ceklistugasku:hover > * {
    color: var(--basecolorgreen);
}
.aplikasiku-wrapper .listtugasku a.kembalikantugasku:hover > * {
    color: var(--basecolorgreen);
}
@media screen and (max-width: 768px) {
    .aplikasiku-wrapper .sidebar {
        width: 0px !important;
        left: -100% !important;
    }
}
.tugaskulist {
    padding: 0px 15px;
}
.changelayoutlistcatatan {
    cursor:pointer;
    color: var(--basecolor2);
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.changelayoutlistcatatan:hover {
    background: var(--basecolor5);
}
.changelayoutlistcatatangrid * {font-size: 1.2rem;}
.changelayoutlistcatatanlist * {font-size: 1.25rem;}

.kosongkansampahcatatan {display: none;}

.boxformeditkategori {
    width:50%;
    display: none;
}
#popupeditkategori .modal-body {padding-bottom: 0px !important;}
@media screen and (max-width: 768px) {
    .boxformeditkategori {width:100%;}
    .btntambahkategori {margin-top: 1rem !important;}
}


/* .list_notes {
    margin-top: 30px;
}
.list_notes .list {
    cursor:pointer;
    word-break:break-word;
    display: block;
    margin-bottom: 20px;
    border: 1px solid #C8C9CA;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 5px 28px 0 rgb(0 0 0 / 10%);
}
.list_notes .list .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */


:root {  
    --ff-primary: basic-sans, sans-serif;
    --clr-primary: #ee6352;
    --clr-body: #333;
    --clr-bg: #ddd;
    --spacer: 1rem;
}
.catatan-list-list .content {
    margin-bottom: 1em !important;
}
.catatan-list-grid {
    --gap: 1em;
    --columns: 4;
    max-width: 100%;
    margin: 0 auto;
    display: column;
    columns: var(--columns);
    gap: var(--gap);
}
@media screen and (max-width: 768px) {
    .catatan-list-grid {
        --gap: 0.5em;
    }
    .catatan-list-list .content {
        margin-bottom: 0.5em !important;
    }
}
.catatan-list-grid > * {
    break-inside: avoid;
    margin-bottom: var(--gap);
}
@supports (grid-template-rows: masonry) {
    .catatan-list-grid {
        display: grid;
        grid-template-columns: repeat(var(--columns), 1fr);
        grid-template-rows: masonry;
        grid-auto-flow: dense;
        /* align-tracks: stretch; */
    }
    .catatan-list-grid > * {
        margin-bottom: 0em;
    }
}
.featured {
    grid-column: span 2;
}
.flow > * + * {
    margin-top: var(--flow-space, var(--spacer));
}
.content {
    overflow: hidden;
    word-break:break-word;
    padding: 20px; 
    border: 1px solid var(--basecolor5_2);
    border-radius:12px;
    cursor: pointer;
    background: white;
}
.content:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.list6catatanku .col-lg-6 .list {
    padding: 10px 15px;
    border-radius: 8px;
}
.list6catatanku .col-lg-6 .list:hover {
    background: var(--basecolor5);
}
.list6catatanku h5 {
    margin-bottom: 2px;
    font-size: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list6catatanku p {
    margin-bottom: 0px;
    color: var(--basecolor);
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list6tugasku .col-lg-12 .list,
.list6tugasku .col-lg-6 .list {
    padding: 10px 15px;
    border-radius: 8px;
}
.list6tugasku .col-lg-12 .list:hover,
.list6tugasku .col-lg-6 .list:hover {
    background: var(--basecolor5);
}
.list6tugasku h5 {
    margin-bottom: 2px;
    font-size: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list6tugasku p {
    margin-bottom: 0px;
    color: var(--basecolor);
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.content-footer * {opacity: 0;color: var(--basecolor2);}
.content:hover > .content-footer * {opacity: 1;}
.content:hover > .content-footer hr {opacity: 0.3 !important;}
@media screen and (max-width: 768px) {
    .aplikasiku-wrapper .sidebar {
        width: 0% !important;
        left: -50%;
        padding: 0px;
        border: none;
    }
    .aplikasiku-wrapper .body {
        width: 100%;
    }
    .catatan-list-grid {
        --columns: 2;
    }
    .content {
        padding: 15px;
    }
}

.riplabspartners {
    display: none;
    margin: 60px 0 0 0;
    width:100%;
    background: var(--basecolor1);
    color:white;
    padding: 30px;
    text-align: center;
}
.riplabspartners img:hover { 
    transform: scale(1.08);
}

.footer {
    display: none;
    color: var(--basecolor2);
    padding: 50px 0 25px 0;
    border-top: 1px solid var(--basecolor5);
}
.footer a:hover {
    color: var(--basecolor1) !important;
}
.footer-copyright {
    display: none;
    color: var(--basecolor2);
    padding: 20px;
    font-size:14px;
    text-align: center;
    background-color: var(--basecolor5_3);
    border-top: 1px solid var(--basecolor5);
}

.container-dompetku {
    margin-top: 2% !important;
    width: 80% !important;
    max-width: 80% !important;
}
.boxtotalsaldo h1 {
    font-family: SFProTextMedium;
}
@media screen and (max-width: 768px) {
    .boxtotalsaldo h1 {font:35px/35px SFProTextMedium;margin: 12px 0 !important;}
}
.boxdompetku1 {
    display: block;
    padding: 15px;
    border-radius: 12px;
    width: 100%;
    border: 1px solid var(--basecolor5);
    margin: 30px 0;
    background: white;
}
.boxdompetku1 .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.boxdompetku1 .header h6 {
    font-family: SFProTextMedium;
    margin: 0px;
}
.boxdompetku1 .header span,
.boxdompetku1 .header a {
    text-decoration: none !important;
    outline: none !important;
    font-family: SFProTextMedium;
    color: var(--basecolor1);
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .boxdompetku1 {
        margin: 20px 0;
    }
    .container-dompetku {
        margin-top: 2% !important;
        width:98% !important;
        max-width:98% !important;
    }
}

.header-dompetku {
    z-index: 99;
    width: 100%;
    padding: 15px;
    display: flex;
    position: fixed;
    background: white;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-around;
    border-top: 1px solid var(--basecolor5);
}

.header-dompetku a {
    color: var(--basecolor5_5) !important;
}
.header-dompetku a:hover,
.header-dompetku a.active {
    color: var(--basecolor2) !important;
}

.header-dompetku a {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-decoration: none !important;
    color: var(--basecolor2);
    font-size: 13px !important;
}
.header-dompetku a .fa {
    font-size: 19px !important;
    display: block;
    margin-bottom: 3px;
}

.header-dompetku .tambahtransaksi {
    position: absolute;
    font-size: 1.5rem;
    cursor: pointer;
    top: -10px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--basecolor1);
    color: white;
    border-radius: 50px;
    text-decoration: none !important;
    outline: none !important;
}
.header-dompetku .tambahtransaksi:hover {
    transform: scale(1.1);
}
@media screen and (max-width: 768px) {
    .header-dompetku .tambahtransaksi {
        font-size: 1.1rem;
        top: -10px;
        width: 45px;
        height: 45px;
    }
    .header-dompetku a .fa {
        font-size: 18px !important;
    }
    .header-dompetku a {
        font-size: 10.5px !important;
    }
}

.listperiode {
    padding: 0px !important; 
    display:block;
    white-space: nowrap !important;
    width:100%;
    overflow:auto;
    /* border: 1px solid red; */
}
.listperiode span {
    padding: 15px 20px !important; 
    color: var(--basecolor2);
    text-decoration: none !important;
    cursor: pointer;
    margin-right: 10px;
    display: inline-block;
    border-bottom: 3px solid white;
}
.listperiode span.active {
    border-bottom: 3px solid var(--basecolor1);
    /* font-family: SFProTextMedium; */
    color:var(--basecolor1) !important;
}

.auto{
    display:block;
    border: 1px solid red;
    padding:5px;
    margin-top:5px;
    white-space: nowrap !important;
    width:300px;
    height:30px;
    overflow:auto;
}

.trxlist {
    overflow:hidden;
    width:50%;
}
.trxlist2 {
    overflow:hidden;
    width:100%;
}
.transaksicatatan {
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media screen and (max-width: 768px) {
    .trxlist {
        width:70%;
    }
}

.linklistmenulainnya {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none !important;
    margin: 10px 0 15px 0;
    cursor: pointer;
    padding: 22.5px;
    border-radius: 12px;
    border: 1px solid var(--basecolor5);
}
.linklistmenulainnya:hover > div .fa {
    margin-right: 5px;
}
.linklistmenulainnya div {
    margin: 0px;
    padding: 0px;
}
.linklistmenulainnya h5 {
    font:18px/18px SFProTextMedium;
    color:var(--basecolor2);
    margin-bottom: 12.5px;
}
.linklistmenulainnya p {
    margin: 0px;
    font:15px/24px SFProTextRegular;
    color:var(--basecolor7);
}
.linklistmenulainnya .fa {
    display: block;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 1.35rem !important;
    color: var(--basecolor1);
}

.pagination .page-link {color: var(--basecolor1);}
.pagination .active {background: var(--basecolor1) !important;color:white !important;border-color: var(--basecolor1) !important;}


.listkategoridata {
    width: 100%;
    display:block;
    white-space: nowrap !important;
    width:100%;
    overflow:auto;
    text-align: center;
    padding: 10px 0;
}
.listkategoridata span {
    font-family: SFProTextMedium;
    color: var(--basecolor2);
    cursor: pointer;
    padding: 0px 15px;
}
.listkategoridata span.active {
    color: var(--basecolor1);
}
@media screen and (max-width: 768px) {
    .listkategoridata {
        padding:15px 0;
    }
}

.pageartikeldetail .artikel_judul {
    font: 35px/48px SFProTextSemibold;
}
.pageartikeldetail .artikel_thumbnail {
    width: 90% !important;
}
.pageartikeldetail .listartikelterbaru div {
    margin-bottom: 30px;
    display: block;
}
.pageartikeldetail .listartikelterbaru p {
    color: var(--basecolor6);
}
.pageartikeldetail .listartikelterbaru a {
    font: 20px/30px SFProTextMedium;
    margin-bottom: 10px;
    color: var(--basecolor2);
    text-decoration: none !important;
}
.pageartikeldetail .listartikelterbaru a:hover {
    color: var(--basecolor1);
}
@media screen and (max-width: 768px) {
    .pageartikeldetail .artikel_judul {
        font: 27px/38px SFProTextSemibold;
    }
    .pageartikeldetail .artikel_thumbnail {
        width: 100% !important;
    }
}

.boxfiltertargetbelanja select {
    cursor: pointer !important;
}
.boxfiltertargetbelanja select,
.boxfiltertargetbelanja input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.boxfiltertargetbelanja input:focus {
    box-shadow: none !important;
}
.switchstatustargetbelanja {
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-size: 1rem;
    margin: 0px !important;
    margin-left: 30px !important;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50px;
    color: var(--basecolor7);
    text-decoration: none !important;
    background-color: var(--basecolor5_4);
}
.switchstatustargetbelanja:hover {
    color: var(--basecolorgreen);
}
@media screen and (max-width: 768px) {
    .boxfiltertargetbelanja input {
        margin-bottom: 15px !important;
    }
}

.btnlaporbugulasan {
    position: fixed;
    bottom: 0;
    width: 220px;
    left: calc(50% - 110px);
    display: block;
    margin: auto;
    border-radius: 8px 8px 0 0 !important;
}

.colsharefiles {
    display: none;
}
.container-sharefiles-download {
    width: 70%;
    margin: 40px auto;
}
.container-sharefiles-download .boxform {
    display: none;
}
.container-sharefiles-download .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}
.container-sharefiles-download .header .img1 {
    width: 130px;
}
.container-sharefiles-download .header .img2 {
    width: 160px;
}
.container-sharefiles-download .boxdownloadfile {
    margin: 0 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /* border: 1px solid red; */
}
.container-sharefiles-download .boxdownloadfile .left {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.container-sharefiles-download .boxdownloadfile .icon * {
    display: block;
    color: var(--basecolor2);
    vertical-align: top;
}
.container-sharefiles-download .boxdownloadfile .icon {
    font-size: 5.2rem;
}
.container-sharefiles-download .boxdownloadfile .text {
    margin-left: 20px;
    width: 80%;
    text-align: left;
    overflow: hidden;
}
.container-sharefiles-download .boxdownloadfile .text h5 {
    display: block;
    font: 19px/37px SFProTextMedium;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.container-sharefiles-download .boxdownloadfile .text span {
    display: block;
    font: 13px/20px SFProTextRegular;
}
.container-sharefiles-download .boxdownloadfile button:hover {
    filter: brightness(1.2);
}
.container-sharefiles-download .boxdownloadfile button {
    transition: 200ms ease-in-out;
    /* width: 100%; */
    width: 35%;
    padding: 20px;
    border-radius: 12px !important;
    margin: 0px;
    font: 19px/20px SFProTextMedium;
}

.container-sharefiles-download .boxdetailfile {
    
}
.container-sharefiles-download .boxfilenotfound {
    width: 100%;
    border-radius: 12px;
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    margin: 40px 0;
    font: 22px/32px SFProTextMedium;
    color: black;
    background: rgba(220, 47, 2, 0.3);
    
}
.container-sharefiles-download .boxiklan {
    width: 100%;
    border-radius: 12px;
    background: var(--basecolor5_4);
    color:var(--basecolor6);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    margin: 40px 0;
    font: 25px/30px SFProTextSemibold;
}
@media screen and (max-width: 768px) {
    .container-sharefiles-download {
        width:95%;
    }
    .container-sharefiles-download .boxdownloadfile .icon {
        font-size: 4.3rem;
    }
    .container-sharefiles-download .boxdownloadfile .text {
        width: 60%;
    }
    .container-sharefiles-download .boxdownloadfile .text h5 {
        font: 17px/29px SFProTextMedium;
    }
    .container-sharefiles-download .boxdownloadfile .text span {
        font: 11px/17px SFProTextRegular;
    }
    .container-sharefiles-download .boxdownloadfile button {
        padding: 14px;
        font: 16px/25px SFProTextMedium;
    }
}

.dataTables_paginate {margin-top: 10px !important;}
.pagesharefiles a.paginate_button.current {background:var(--basecolor1) !important;}
.pagesharefiles a.paginate_button {border:none !important;border-radius:8px !important;}
.pagesharefiles a.paginate_button:hover {color:white !important;background:var(--basecolor1) !important;}
.pagesharefiles a.paginate_button.current {color:white !important;}

.menutogglepopupintro {
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    cursor: pointer;
    border: 1px solid var(--basecolor5_2);
}
.modalintro .boxintro {
    transition: 200ms ease-in-out;
    padding: 0 10px 12px 10px;
    display: none;
}
.modalintro .boxintro.show {
    display: block !important;
}

#box-menu-apps {
    width: 25%;
}
@media screen and (max-width: 768px) {
    #box-menu-apps {
        width:70% !important;
        max-width:70% !important;
    }
}